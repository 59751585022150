<div class="ax-office-layout">
  @if (isLoggedIn$ | async) {
    <ax-office-side-navigation />
    <ax-office-top-navigation />
  }
  <div class="ax-office-layout__content"
       [class.full-screen]="(isLoggedIn$ | async) === undefined">
    @if (isLoggedIn$ | async) {
      <div class="ax-office-layout__header">
        <ax-ui-breadcrumb [rootBreadcrumb]="rootBreadcrumb"></ax-ui-breadcrumb>
        <ax-office-favorites-bar />
      </div>
    }
    <div class="ax-office-layout__content-body">
      <router-outlet></router-outlet>
      @if (showLoading$ | async) {
        <ax-ui-loading-spinner
          [iconSize]="48"
          [pageLoader]="true"
          loadingState="loading" />
      }
    </div>
  </div>
  <ng-template axUiAlertDialogs></ng-template>
  <ng-template axUiConfirmActionModalHost></ng-template>
  <ng-template axUiModal></ng-template>
</div>
