<div class="ax-office-navigation-dialog">
  <button (click)="toggleDialog()"
          [class.active]="toggleButtonActive"
          class="ax-office-navigation-dialog__trigger">
    <span class="color-overlay"></span>
    <ng-content select="[button]"></ng-content>
  </button>

  @if (showDialog) {
    <div class="ax-dialog-overlay__container">
      <div [class.close]="closeAnimation"
           aria-labelledby="navTitle"
           aria-modal="true"
           class="ax-dialog-overlay"
           role="dialog">
        <div class="ax-dialog-overlay__title">
          <div class="ax-dialog-overlay__title-content">
            <h4 id="navTitle">{{ title }}</h4>
          </div>
          <ax-ui-icon (click)="closeDialog()"
                      [name]="'close'"
                      class="close-button">
          </ax-ui-icon>
        </div>
        <ng-content select="[dialog-content]"></ng-content>
      </div>
    </div>
  }
</div>
