import { Component, Input } from '@angular/core';
import { AvatarComponent, IconComponent, TooltipDirective } from '@axova-frontend-monorepo/axova-ui';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ax-office-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    TranslateModule,
    IconComponent,
    TooltipDirective
  ]
})
export class PageHeaderComponent {
  @Input() titleText: string | undefined = '';
  @Input() additionalInfoText: string | undefined = '';
  @Input() subTitleText: string | undefined = '';
  @Input() tooltipText: string | undefined = '';
  @Input() noMargins = false;
  @Input() handleTextOverflow = false;
  @Input() noAdditionalContent = false;
  @Input() smallAdditionalContent = false;
  @Input() noGridLayout = false;
  @Input() avatarImgSrc: string | null = '';
}
