<div class="ax-office-top-navigation">
  <div class="ax-office-top-navigation__style-helper"></div>
  <div class="ax-office-top-navigation__search">
    <ax-ui-input-field (clickedInsideInput)="isSearchVisible = this.searchterm.length > 2"
                       (valueChange)="search($event)"
                       [(loadingState)]="searchLoadingState"
                       [debounceTimeInMs]="500"
                       [disableHint]="true"
                       [placeholder]="'SUCHEN' | translate"
                       [showClearButton]="true"
                       [value]="searchterm"
                       iconName="search" />
    <ax-office-megasearch-results (contactClicked)="resetAndHideSearch()"
                                  (projectClicked)="resetAndHideSearch()"
                                  (userClicked)="resetAndHideSearch()"
                                  [(isVisible)]="isSearchVisible"
                                  [axMegasearchResultDto]="axMegasearchResultDto"
                                  [renderContactAsLink]="true"
                                  [renderEquipmentAsLink]="true"
                                  [renderProjectAsLink]="true"
                                  [renderUserAsLink]="true"
                                  [renderStocklocationAsLink]="true"
                                  [searchterm]="searchterm"
                                  [showContactAndProjects]="true"
                                  [showEquipments]="true"
                                  [showStocklocations]="true"
                                  [showItems]="true"
                                  [showUsers]="true" />
  </div>
  <div class="ax-office-top-navigation__profile">
    <div class="logo">
      <ax-ui-logo context="office" variant="white" />
    </div>
    <div class="actions">
      <ax-office-notifications-dialog />
      <ax-office-profile-options-dialog />
    </div>
  </div>
</div>
