import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  AxActivityTypesV2Service,
  AxArchiveboxesV2Service,
  AxAudiencesV2Service,
  AxAuthenticatorsV2Service,
  AxBusinessdivisionsV2Service,
  AxBusinessUnitsV2Service,
  AxCategoriesV2Service,
  AxLeadCategoriesV2Service,
  AxLeadSubCategoriesV2Service,
  AxLocationsV2Service,
  AxMailtypesV2Service,
  AxOutcometypesV2Service,
  AxPhonetypesV2Service,
  AxProjectAnlageSupplyTypesEnum,
  AxProjectAnlageTypesEnum,
  AxProjectSystemTypesEnum,
  AxRealEstateTypesV2Service,
  AxStandardtasksV2Service,
  AxTagsV2Service,
  AxTeamsV2Service,
  AxTimerecordtypesV2Service,
  AxTitlesV2Service,
  AxUsersV2Service
} from '@axova-frontend-monorepo/axova-rest-api';
import { SelectDropdownOptions } from '@axova-frontend-monorepo/axova-ui';

@Injectable({
  providedIn: 'root'
})
export class SelectDropdownOptionsService {
  public activityTypes$: Observable<SelectDropdownOptions[]>;
  public archieveBoxes$: Observable<SelectDropdownOptions[]>;
  public audiences$: Observable<SelectDropdownOptions[]>;
  public authenticators$: Observable<SelectDropdownOptions[]>;
  public axElektriker$: Observable<SelectDropdownOptions[]>;
  public axExtern$: Observable<SelectDropdownOptions[]>;
  public axIntern$: Observable<SelectDropdownOptions[]>;
  public axKundenberater$: Observable<SelectDropdownOptions[]>;
  public axProjektleiter$: Observable<SelectDropdownOptions[]>;
  public businessdivisions$: Observable<SelectDropdownOptions[]>;
  public businessunits$: Observable<SelectDropdownOptions[]>;
  public cantons$: Observable<SelectDropdownOptions[]>;
  public categories$: Observable<SelectDropdownOptions[]>;
  public contactTitles$: Observable<SelectDropdownOptions[]>;
  public leadCategories$: Observable<SelectDropdownOptions[]>;
  public leadSubCategories$: Observable<SelectDropdownOptions[]>;
  public locations$: Observable<SelectDropdownOptions[]>;
  public mailTypes$: Observable<SelectDropdownOptions[]>;
  public outcomeTypes$: Observable<SelectDropdownOptions[]>;
  public phoneTypes$: Observable<SelectDropdownOptions[]>;
  public standardtasks$: Observable<SelectDropdownOptions[]>;
  public tags$: Observable<SelectDropdownOptions[]>;
  public teams$: Observable<SelectDropdownOptions[]>;
  public timerecordtypes$: Observable<SelectDropdownOptions[]>;
  public realestatetypes$: Observable<SelectDropdownOptions[]>;
  public projectAnlageTypes: SelectDropdownOptions[] = Object.values(AxProjectAnlageTypesEnum).map(value => ({
    label: value,
    value
  }));
  public projectSystemTypes: SelectDropdownOptions[] = Object.values(AxProjectSystemTypesEnum).map(value => ({
    label: value,
    value
  }));
  public projectAnlageSupplyTypes: SelectDropdownOptions[] = Object.values(AxProjectAnlageSupplyTypesEnum).map(value => ({
    label: value,
    value
  }));

  constructor(
    private readonly axActivityTypesV2Service: AxActivityTypesV2Service,
    private readonly axArchiveboxesV2Service: AxArchiveboxesV2Service,
    private readonly axAudiencesV2Service: AxAudiencesV2Service,
    private readonly axBusinessdivisionsV2Service: AxBusinessdivisionsV2Service,
    private readonly axBusinessUnitsV2Service: AxBusinessUnitsV2Service,
    private readonly axCategoriesV2Service: AxCategoriesV2Service,
    private readonly axLeadCategoriesV2Service: AxLeadCategoriesV2Service,
    private readonly axLeadSubCategoriesV2Service: AxLeadSubCategoriesV2Service,
    private readonly axLocationsV2Service: AxLocationsV2Service,
    private readonly axMailtypesV2Service: AxMailtypesV2Service,
    private readonly axOutcometypesV2Service: AxOutcometypesV2Service,
    private readonly axPhonetypesV2Service: AxPhonetypesV2Service,
    private readonly axTagsV2Service: AxTagsV2Service,
    private readonly axTeamsV2Service: AxTeamsV2Service,
    private readonly axTimerecordtypesV2Service: AxTimerecordtypesV2Service,
    private readonly axTitlesV2Service: AxTitlesV2Service,
    private readonly axUsersV2Service: AxUsersV2Service,
    private readonly axStandardtasksV2Service: AxStandardtasksV2Service,
    private readonly axRealEstateTypesV2Service: AxRealEstateTypesV2Service,
    private readonly authenticatorsV2Service: AxAuthenticatorsV2Service
  ) {
    this.activityTypes$ = defer(() => this.axActivityTypesV2Service.activitytypesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.archieveBoxes$ = defer(() => this.axArchiveboxesV2Service.archiveboxesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.audiences$ = defer(() => this.axAudiencesV2Service.audiencesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.authenticators$ = defer(() => this.authenticatorsV2Service.authenticatorsControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.axElektriker$ = defer(() => this.axUsersV2Service.usersControllerGetAllDropdownOptions({ roles: ['ax_elektriker'].join(',') })).pipe(shareReplay(1));
    this.axExtern$ = defer(() => this.axUsersV2Service.usersControllerGetAllDropdownOptions({ roles: ['ax_extern'].join(',') })).pipe(shareReplay(1));
    this.axIntern$ = defer(() => this.axUsersV2Service.usersControllerGetAllDropdownOptions({ roles: ['ax_intern'].join(',') })).pipe(shareReplay(1));
    this.axKundenberater$ = defer(() => this.axUsersV2Service.usersControllerGetAllDropdownOptions({ roles: ['ax_kundenberater'].join(',') })).pipe(shareReplay(1));
    this.axProjektleiter$ = defer(() => this.axUsersV2Service.usersControllerGetAllDropdownOptions({ roles: ['ax_projektleiter'].join(',') })).pipe(shareReplay(1));
    this.businessdivisions$ = defer(() => this.axBusinessdivisionsV2Service.businessdivisionsControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.businessunits$ = defer(() => this.axBusinessUnitsV2Service.businessunitsControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.cantons$ = defer(() => this.axLocationsV2Service.locationsControllerGetAllDropdownOptionsCantons()).pipe(shareReplay(1));
    this.categories$ = defer(() => this.axCategoriesV2Service.categoriesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.contactTitles$ = defer(() => this.axTitlesV2Service.titlesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.leadCategories$ = defer(() => this.axLeadCategoriesV2Service.leadcategoriesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.leadSubCategories$ = defer(() => this.axLeadSubCategoriesV2Service.leadsubcategoriesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.locations$ = defer(() => this.axLocationsV2Service.locationsControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.mailTypes$ = defer(() => this.axMailtypesV2Service.mailtypesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.outcomeTypes$ = defer(() => this.axOutcometypesV2Service.outcometypesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.phoneTypes$ = defer(() => this.axPhonetypesV2Service.phonetypesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.realestatetypes$ = defer(() => this.axRealEstateTypesV2Service.realestateTypesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.standardtasks$ = defer(() => this.axStandardtasksV2Service.standardtasksControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.tags$ = defer(() => this.axTagsV2Service.tagsControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.teams$ = defer(() => this.axTeamsV2Service.teamsControllerGetAllDropdownOptions()).pipe(shareReplay(1));
    this.timerecordtypes$ = defer(() => this.axTimerecordtypesV2Service.timerecordtypesControllerGetAllDropdownOptions()).pipe(shareReplay(1));
  }
}
