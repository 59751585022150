@if (axMegasearchResultDto) {
  <div #resultList
       [class.ax-office-megasearch-results--align-left]="alignLeft"
       [class.ax-office-megasearch-results--is-inline]="isInline"
       [class.slide-in-from-top.fade-in]="isVisible"
       [class.slide-out-to-top.fade-out]="!isVisible"
       class="ax-office-megasearch-results">
    @if (hasNoResults) {
      <p class="ax-office-megasearch-results--none color-light">{{ 'KEINE_SUCHTREFFER' | translate }}</p>
    }
    @if (axMegasearchResultDto && axMegasearchResultDto.contactAndProjects.length && showContactAndProjects) {
      <div class="ax-office-megasearch-results-group">
        <h4>{{ 'KONTAKTE' | translate }} & {{ 'PROJEKTE' | translate }}</h4>
        @for (contactAndProjects of axMegasearchResultDto.contactAndProjects; track contactAndProjects) {
          <div class="ax-office-megasearch-results-item">
            <ax-office-megasearch-result-contact-and-projects
              (contactClicked)="contactClicked.emit($event); axMegasearchResultDto = undefined; toggleResults(false)"
              (projectClicked)="projectClicked.emit($event); axMegasearchResultDto = undefined; toggleResults(false)"
              [contactAndProjects]="contactAndProjects"
              [renderContactAsLink]="renderContactAsLink"
              [renderProjectAsLink]="renderProjectAsLink"
              [searchterm]="searchterm" />
          </div>
        }
      </div>
    }
    @if (axMegasearchResultDto && axMegasearchResultDto.items && axMegasearchResultDto.items.length && showItems) {
      <div class="ax-office-megasearch-results-group">
        <h4>{{ 'ARTIKEL' | translate }}</h4>
        <div class="ax-grid-container">
          <div class="ax-grid-row">
            @for (item of axMegasearchResultDto.items; track item) {
              <div class="ax-grid-col-desktop-6 ax-grid-col-tablet-12 ax-grid-col-mobile-12">
                <ax-ui-infoblock (click)="axMegasearchResultDto = undefined; toggleResults(false)"
                                 [avatarImgSrc]="((item.entity.documents | getItemImageFromDocumentsPipe)?.fileSrcThumbnail | prependServerToFileSrc) || ''"
                                 [avatarSquare]="true"
                                 [clickable]="true"
                                 [iconName]="!((item.entity.documents | getItemImageFromDocumentsPipe)?.fileSrcThumbnail) ? 'package_2' : ''"
                                 [label]="(item.entity.productgroup ? item.entity.productgroup.name : null) | highlight:searchterm"
                                 [listElement]="true"
                                 [subtitle]="(item.entity.producer ? item.entity.producer.company : null) | highlight:searchterm"
                                 [title]="(item.entity.name || '-') | highlight:searchterm"
                                 [withAvatar]="!!((item.entity.documents | getItemImageFromDocumentsPipe)?.fileSrcThumbnail)"
                                 avatarStatus="none"
                                 route="/artikel/read/{{ item.entity.id }}" />
              </div>
            }
          </div>
        </div>
      </div>
    }
    @if (axMegasearchResultDto && axMegasearchResultDto.equipments && axMegasearchResultDto.equipments.length && showEquipments) {
      <div class="ax-office-megasearch-results-group">
        <div class="ax-grid-container">
          <div class="ax-grid-row">
            @for (equipment of axMegasearchResultDto.equipments; track equipment) {
              <div class="ax-grid-col-desktop-6 ax-grid-col-tablet-12 ax-grid-col-mobile-12">
                <ax-ui-infoblock
                  (click)="equipmentClicked.emit(equipment.entity);axMegasearchResultDto = undefined; toggleResults(false)"
                  [clickable]="true"
                  [iconName]="equipment.entity.equipmenttype.id | getEquipmenttypeIconName"
                  [label]="equipment.entity.equipmenttype.name | highlight:searchterm"
                  [listElement]="true"
                  [route]="renderEquipmentAsLink ? '/betriebsmittel/read/' + equipment.entity.id : undefined"
                  [subtitle]="equipment.entity.identifier | highlight:searchterm"
                  [title]="equipment.entity.brand + ' ' + equipment.entity.model | highlight:searchterm"
                  avatarStatus="none" />
              </div>
            }
          </div>
        </div>
      </div>
    }
    @if (axMegasearchResultDto && axMegasearchResultDto.stocklocations && axMegasearchResultDto.stocklocations.length && showStocklocations) {
      <div class="ax-office-megasearch-results-group">
        <h4>{{ 'LAGERORTE' | translate }}</h4>
        <div class="ax-grid-container">
          <div class="ax-grid-row">
            @for (stocklocation of axMegasearchResultDto.stocklocations; track stocklocation) {
              <div class="ax-grid-col-desktop-6 ax-grid-col-tablet-12 ax-grid-col-mobile-12">
                <ax-ui-infoblock
                  (click)="stocklocationClicked.emit(stocklocation.entity);axMegasearchResultDto = undefined; toggleResults(false)"
                  [clickable]="true"
                  [iconName]="'home'"
                  [listElement]="true"
                  [title]="stocklocation.entity.name | highlight:searchterm"
                  avatarStatus="none" />
              </div>
            }
          </div>
        </div>
      </div>
    }
    @if (axMegasearchResultDto && axMegasearchResultDto.users && axMegasearchResultDto.users.length && showUsers) {
      <div class="ax-office-megasearch-results-group">
        <h4>{{ 'TEAM' | translate }}</h4>
        @for (user of axMegasearchResultDto.users; track user; let i = $index) {
          <div (click)="userClicked.emit(user.entity); toggleResults(false)"
               [class.margin-bottom-24]="i < axMegasearchResultDto.users.length - 1">
            <ax-ui-infoblock
              [avatarImgSrc]="user.entity.userImage ? (user.entity.userImage.fileSrcThumbnail | prependServerToFileSrc) : null"
              [clickable]="true"
              [label]="user.entity.jobtitle || ''"
              [listElement]="true"
              [route]="renderUserAsLink ? '/mitarbeiter/read/' + user.entity.id : undefined"
              [subtitle]="user.foundInProperty | joinBy:', '"
              [title]="user.entity.firstName! + ' ' + user.entity.lastName! || ''"
              [withAvatar]="true"
              avatarStatus="none"
              route="/mitarbeiter/read/{{ user.entity.id }}" />
          </div>
        }
      </div>
    }
  </div>
}
