import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { AxEquipment, AxMegasearchResultDto, AxStocklocation, AxUser } from '@axova-frontend-monorepo/axova-rest-api';
import {
  MegasearchResultClickedInterface,
  MegasearchResultContactAndProjectsComponent
} from '../megasearch-result-contact-and-projects/megasearch-result-contact-and-projects.component';
import {
  GetEquipmenttypeIconNamePipe,
  GetItemImageFromDocumentsPipe,
  HighlightPipe,
  JoinByPipe,
  PrependServerToFileSrcPipe
} from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';
import { InfoblockComponent } from '@axova-frontend-monorepo/axova-ui';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'ax-office-megasearch-results',
  templateUrl: './megasearch-results.component.html',
  styleUrls: ['./megasearch-results.component.scss'],
  standalone: true,
  imports: [
    MegasearchResultContactAndProjectsComponent,
    InfoblockComponent,
    TranslateModule,
    GetItemImageFromDocumentsPipe,
    PrependServerToFileSrcPipe,
    HighlightPipe,
    GetEquipmenttypeIconNamePipe,
    JoinByPipe,
    JsonPipe,
  ],
})
export class MegasearchResultsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChildren('resultList') resultList!: QueryList<ElementRef>;

  @Input({ required: true }) axMegasearchResultDto: AxMegasearchResultDto | undefined;
  @Input({ required: true }) searchterm!: string;
  @Input() showContactAndProjects = false;
  @Input() showEquipments = false;
  @Input() showStocklocations = false;
  @Input() showItems = false;
  @Input() showUsers = false;
  @Input() isVisible = false;
  @Input() isInline = false;
  @Input() alignLeft = false;
  @Input() renderUserAsLink = false;
  @Input() renderContactAsLink = false;
  @Input() renderProjectAsLink = false;
  @Input() renderEquipmentAsLink = false;
  @Input() renderStocklocationAsLink = false;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() contactClicked = new EventEmitter<MegasearchResultClickedInterface>();
  @Output() projectClicked = new EventEmitter<MegasearchResultClickedInterface>();
  @Output() userClicked = new EventEmitter<AxUser>();
  @Output() equipmentClicked = new EventEmitter<AxEquipment>();
  @Output() stocklocationClicked = new EventEmitter<AxStocklocation>();
  public hasNoResults = false;

  constructor(
    private readonly elementRef: ElementRef,
    private renderer: Renderer2) {
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.toggleResults(false);
    }
  }

  @HostListener('click', ['$event'])
  onComponentClick(event: MouseEvent) {
    // Handle component click, prevent it from propagating to document
    event.stopPropagation();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setDropDownMaxHeight();
  }

  ngOnInit() {
    // Add a click listener to the document
    document.addEventListener('click', this.handleDocumentClick.bind(this), true);
  }

  ngOnDestroy() {
    // Remove the click listener when the component is destroyed
    document.removeEventListener('click', this.handleDocumentClick.bind(this), true);
  }

  handleDocumentClick(event: MouseEvent) {
    // Check if the click is outside the component
    const resultsPresent = this.elementRef.nativeElement.querySelector('.ax-office-megasearch-results');
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (resultsPresent && !clickedInside) {
      this.toggleResults(false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const megasearchResultChanges = changes['axMegasearchResultDto'];
    if (megasearchResultChanges && megasearchResultChanges.currentValue && megasearchResultChanges.currentValue !== megasearchResultChanges.previousValue) {
      this.toggleResults(true);
      this.hasNoResults =
        (!this.axMegasearchResultDto?.contactAndProjects || !this.axMegasearchResultDto?.contactAndProjects.length) &&
        (!this.axMegasearchResultDto?.items || !this.axMegasearchResultDto?.items.length) &&
        (!this.axMegasearchResultDto?.equipments || !this.axMegasearchResultDto?.equipments.length) &&
        (!this.axMegasearchResultDto?.stocklocations || !this.axMegasearchResultDto?.stocklocations.length) &&
        (!this.axMegasearchResultDto?.users || !this.axMegasearchResultDto?.users.length);
    }
  }

  public toggleResults(visible: boolean) {
    this.isVisible = visible;
    this.isVisibleChange.emit(this.isVisible);
    if (this.isVisible) {
      this.renderer.setStyle(document.documentElement, 'overflow-y', 'hidden');
      // wait for dropdown to appear in the view
      setTimeout(() => {
        this.setDropDownMaxHeight();
      }, 100);
    } else {
      this.renderer.setStyle(document.documentElement, 'overflow-y', 'auto');
    }
  }

  private setDropDownMaxHeight() {
    if (this.resultList.first) {
      const optionListElement = this.resultList.first.nativeElement;
      let dropdownPosition = optionListElement.getBoundingClientRect().top;
      let maxHeight: number;

      const viewportHeight = window.innerHeight;
      const thirdOfViewportHeight = viewportHeight * 0.333;
      const twoThirdsOfViewportHeight = viewportHeight * 0.666;
      const bottomMarginInputFieldDistance = 24;
      const bottomMargin = this.isInline ? bottomMarginInputFieldDistance : thirdOfViewportHeight;

      // place dropdown above input if the input is placed in the last third of the screen
      if (dropdownPosition > twoThirdsOfViewportHeight) {
        const searchInput = this.elementRef.nativeElement.parentElement.querySelector('ax-ui-input-field');
        const searchInputDistanceToTop = searchInput.getBoundingClientRect().top;
        dropdownPosition = viewportHeight - searchInput.getBoundingClientRect().bottom;
        maxHeight = (searchInputDistanceToTop < 500 ? searchInputDistanceToTop : 500) - dropdownPosition;
        const transformToTopValue = maxHeight;
        optionListElement.style.top = `-${transformToTopValue + bottomMarginInputFieldDistance}px`;
      } else {
        maxHeight = viewportHeight - dropdownPosition - bottomMargin;
      }
      optionListElement.style.maxHeight = `${maxHeight}px`;
    }
  }
}
